<template>
  <main ref="loader" :class="[backgroundColour]">
    <div>
      <div v-if="content" class="blocks">
        <template v-for="(block, i) in content">
          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'hero'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockHero :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'text'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockText
                :block="block"
                :class="[{ 'remove-spacing': blockRemoveSpacing && i < 2 }]"
              />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'copy'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div
              :id="
                block.heading
                  ? block.heading.toLowerCase().replaceAll(' ', '-')
                  : null
              "
              :class="`block-${block.type}-wrapper anchored`"
            >
              <BlockText :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'paragraphs'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockParagraphs :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'video'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockVideo :block="block" v-bind="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'caseStudy'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockCase :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'split'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div
              :id="
                block.title
                  ? block.title.toLowerCase().replaceAll(' ', '-')
                  : null
              "
              :class="`block-${block.type}-wrapper anchored`"
            >
              <BlockSplit :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'card'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
            :class="`block-${block.type}-wrapper`"
          >
            <div class="block-card-wrapper__inner">
              <BlockCard @loaded="update()" :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'carousel'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div
              :id="
                block.title
                  ? block.title.toLowerCase().replaceAll(' ', '-')
                  : null
              "
              :class="`block-${block.type}-wrapper anchored`"
            >
              <BlockCarousel @ready="update()" :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'list'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div
              :id="
                block.heading
                  ? block.heading.toLowerCase().replaceAll(' ', '-')
                  : null
              "
              :class="`block-${block.type}-wrapper anchored`"
            >
              <BlockList :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'options'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockOptionCards :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'cardEntries'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockCardEntries :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'person'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockPerson :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'embed'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div
              :id="
                block.title
                  ? block.title.toLowerCase().replaceAll(' ', '-')
                  : null
              "
              :class="`block-${block.type}-wrapper`"
            >
              <BlockEmbed :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'plain'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockPlain :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'steps'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockSteps :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'details'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockDetails :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'callToAction'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockCta :block="block" />
            </div>
          </BlockMultiWave>

          <BlockMultiWave
            :key="block.id"
            v-if="block.type === 'form'"
            :block="block"
            @mask="setMask(i, $event)"
            :lastMask="getMask(i - 1)"
          >
            <div :class="`block-${block.type}-wrapper`">
              <BlockForm :block="block" />
            </div>
          </BlockMultiWave>
        </template>
      </div>

      <!-- Cookiebot -->
      <div class="cookiebot">
        <div class="cookiebot__container">
          <div ref="cookiebotscript" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { loader, maskBus } from '@/mixins';
import {
  BlockHero,
  BlockText,
  BlockParagraphs,
  BlockVideo,
  BlockCase,
  BlockSplit,
  BlockCarousel,
  BlockList,
  BlockOptionCards,
  BlockCard,
  BlockCardEntries,
  BlockPerson,
  BlockEmbed,
  BlockPlain,
  BlockSteps,
  BlockDetails,
  BlockCta,
  BlockForm,
  BlockMultiWave
} from '@/components/Block';

export default {
  name: 'Page',
  components: {
    BlockHero,
    BlockText,
    BlockParagraphs,
    BlockVideo,
    BlockCase,
    BlockSplit,
    BlockCarousel,
    BlockList,
    BlockOptionCards,
    BlockCard,
    BlockCardEntries,
    BlockPerson,
    BlockEmbed,
    BlockPlain,
    BlockSteps,
    BlockDetails,
    BlockCta,
    BlockForm,
    BlockMultiWave
  },
  data() {
    return {
      page: null,
      content: null
    };
  },
  mixins: [loader, maskBus],
  computed: {
    backgroundColour() {
      return this.page
        ? `bg-brand-${this.page.options.backgroundColour}`
        : null;
    },
    // if first block is a hero and the second block is a text block
    // remove padding-top spacing
    blockRemoveSpacing() {
      if (!this.page) return false;

      if (
        this.page.blocks[0].type === 'hero' &&
        this.page.blocks[1].type === 'text'
      )
        return true;
      return false;
    }
  },
  mounted() {
    if (this.$route.params.slug === 'cookie-policy') {
      this.initCookieBotDeclaration();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.slug === 'cookie-policy') {
      this.initCookieBotDeclaration();
    } else if (this.$refs && this.$refs.cookiebotscript) {
      this.$refs.cookiebotscript.innerHTML = '';

      const scripts = Array.from(document.scripts);
      const cdreport = scripts.find(script => {
        const src = script.getAttribute('src');
        if (!src) return;

        return src.includes('cdreport');
      });

      if (cdreport) {
        cdreport.parentNode.removeChild(cdreport);
      }
    }
    next();
  },
  methods: {
    initCookieBotDeclaration() {
      // console.log('initCookieBotDeclaration()');
      // Create cookiebot <script> tag
      const cookiebotDeclaration = document.createElement('script');
      cookiebotDeclaration.id = 'CookieDeclaration';
      cookiebotDeclaration.src =
        'https://consent.cookiebot.com/d727c528-c2b5-4eee-804c-4f40e3a08b60/cd.js';
      cookiebotDeclaration.type = 'text/javascript';
      cookiebotDeclaration.async = true;

      this.$refs.cookiebotscript.appendChild(cookiebotDeclaration);
    },
    getContent(to) {
      const slug = to ? to : this.slug;
      this.$http
        .get(`api/v1/pages/${slug}.json`)
        .then(response => {
          this.page = response.data;
          this.content = response.data.blocks;
          this.pageTitle = response.data.title;
        })
        .catch(error => console.error(error));
    }
  }
};
</script>

<style lang="postcss">
.blocks {
  .block-card-wrapper {
    &__inner {
      @apply pt-40;
      @apply overflow-auto;
    }
    &:nth-child(n + 3) {
      .block-card-wrapper__inner {
        @apply pt-0;
      }
    }
    .block-card {
      .block-card__container {
        @apply flex-col;
        @screen sm {
          @apply flex-row;
        }
      }
    }
    &:last-of-type {
      @screen sm {
        .block-card-wrapper__inner {
          @apply pb-40;
        }
      }
      .block-card__divider {
        @apply hidden;
      }
    }
    &:nth-child(even) {
      .block-card {
        .block-card__container {
          @screen sm {
            @apply flex-row-reverse;
          }
          .block-card__media {
            .block-card__media__container {
              @apply ml-0;
            }
          }
        }
      }
    }
    &:nth-child(odd) {
      .block-card {
        .block-card__container {
          .block-card__content {
            .block-card__content__container {
              @apply ml-auto;
            }
          }
        }
      }
    }
  }
  .block-cardEntries-wrapper {
    @apply min-h-screen;
  }
  .block-card {
    &__content__copy__cta {
      @apply justify-start;
      @apply mb-16;
      @screen sm {
        @apply mb-0;
      }
    }
  }
  /* .block-text-wrapper {
    &:last-of-type {
      .block-text {
        h3 {
          @apply font-ginto-regular;
          font-size: calc(20px + (32 - 20) * (100vw - 640px) / (1920 - 640));
        }
      }
    }
  } */
}

/* only for cookies policy page */
.cookiebot {
  font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
  &__container {
    max-width: 90%;
    @apply mx-auto;
    @screen sm {
      @apply max-w-screen-sm;
    }
  }
}
</style>
